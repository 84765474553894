<template>
  <div v-if="false">
    <v-row>
      <v-col cols="12" md="6">
        <molecules-summary-card
          icon="account-multiple"
          :title="`${$store.state.client.total || 0} Clients`"
          :desc="`Sampai Tanggal ${$moment().format('DD MMMM YYYY')}`"
          :loading="reportLoading"
        />
      </v-col>
      <v-col cols="12" md="6">
        <molecules-summary-card
          icon="account-multiple-plus"
          color="green"
          :title="`${Number(
            (typeof $store.state.client.currentDay == 'number' && $store.state.client.currentDay) ||
              (typeof $store.state.client.currentDay == 'object' && $store.state.client.currentDay.total) ||
              0,
          )} Terdaftar 7 Hari Terakhir`"
          :desc="`${$moment().subtract(7, 'days').format('DD MMMM YYYY')} - ${$moment().format('DD MMMM YYYY')}`"
          :loading="reportLoading"
        />
      </v-col>
    </v-row>

    <v-form @submit.prevent="loadData" id="filter" class="mt-4">
      <!-- <atoms-text class="pa-2"
        ><v-icon left>mdi-magnify</v-icon
        ><span
          >Pencarian: ditemukan
          {{
            ($store.state.client.pagesLength
              ? $store.state.client.pagesLength
              : 0) *
              (($store.state.client.limit || 9) - 1) +
              "+" || 0
          }}
          client</span
        ></atoms-text
      > -->
      <v-row justify="space-between" align="center">
        <v-col cols="12" md="8">
          <!-- <atoms-text-field
            label="Cari Clients: ketikkan code, nama ataupun alamat dll..."
            v-model.trim="term"
            :noLabel="true"
            :appendAction="
              () => {
                term = ''
                getClients()
              }
            "
            appendIcon="mdi-close"
          /> -->
          <v-form
            v-if="!client.enabled"
            @submit.prevent="
              () => {
                getClients()
                client.enabled = true
              }
            "
          >
            <atoms-text-field class="rounded-lg accent" noLabel>
              <v-text-field
                v-model.trim="client.term"
                :style="{
                  'box-shadow': 'rgba(0, 0, 0, 0.05) 0px 0px 0px 1px',
                }"
                @click:append="
                  () => {
                    client = {
                      enabled: false,
                      value: '',
                      item: null,
                      term: '',
                    }
                    loadData()
                  }
                "
                placeholder="Ketikkan kata kunci pencarian..."
                appendIcon="mdi-close"
                class="rounded-lg ma-0 pa-0"
                hide-details
                flat
                solo
                dense
              >
                <template v-slot:prepend>
                  <v-combobox
                    class="rounded-lg ma-0 pa-0"
                    placeholder="Query"
                    v-model="searchFilter"
                    :items="searchFilters.map((x) => x.title)"
                    hide-details
                    multiple
                    dense
                    solo
                    flat
                    required
                  >
                    <template v-slot:selection="{ item, index }">
                      <span style="white-space: nowrap" class="pa-0"
                        >{{ (index == 0 && item) || (index == 1 && '..') || '' }}
                      </span>
                    </template></v-combobox
                  >
                </template></v-text-field
              ></atoms-text-field
            >
          </v-form>
          <atoms-text-field v-else label="Cari Client" :no-label="true">
            <v-row dense>
              <v-col cols="1">
                <atoms-button
                  icon
                  @click="
                    () => {
                      client = {
                        enabled: false,
                        value: '',
                        item: null,
                        term: '',
                      }
                      loadData()
                    }
                  "
                  ><v-icon>mdi-delete</v-icon></atoms-button
                >
              </v-col>
              <v-col cols="11">
                <v-autocomplete
                  class="rounded-lg"
                  v-model="client.value"
                  :items="$store.state.client.data.map((x) => x.name)"
                  :placeholder="` ${client.term ? `Pencarian Sekarang : ${client.term}.` : ''} Pilih Client (Opsional)`"
                  :menu-props="{ offsetY: true }"
                  hide-details
                  dense
                  solo
                  flat
                />
              </v-col>
            </v-row>
          </atoms-text-field>
        </v-col>
        <v-col cols="6" md="2" class="d-flex justifyx-space-between">
          <atoms-button
            @click="client.enabled = true"
            type="submit"
            style="width: 100%"
            class="primary lighten-5 primary--text"
            ><v-icon left>mdi-magnify</v-icon>Cari Clients</atoms-button
          >

          <!-- <atoms-button :border="true" style="width: 100%"
            ><v-icon left>mdi-filter-variant</v-icon>Filter</atoms-button
          > -->
        </v-col>
        <v-col cols="6" md="2">
          <atoms-button @click="showAdd = true" style="width: 100%" class="primary"
            ><v-icon left>mdi-plus</v-icon>Tambah Client</atoms-button
          >
        </v-col>
        <v-col cols="12" md="auto">
          <atoms-button
            class="primary"
            style="width: 100%"
            @click="
              () => {
                sort = sort === constant[0] ? constant[1] : constant[0]
                getClients()
              }
            "
            ><v-icon left>mdi-filter-variant-{{ sort === constant[0] ? 'minus' : 'plus' }}</v-icon
            >Sort Tanggal Dibuat: {{ sort }}</atoms-button
          >
        </v-col>

        <v-col cols="12" md="auto" align="start" class="d-flex justify-center justify-md-start align-center grow">
          <v-checkbox v-model="enableDate" hide-spin-buttons /><atoms-text>Filter dengan tanggal pembuatan?</atoms-text>
        </v-col>
        <v-col cols="12" md="auto" class="">
          <v-combobox
            class="rounded-lg"
            placeholder="Filters"
            prepend-inner-icon="mdi-filter-variant"
            v-model="filter"
            :items="filters.map((x) => x.title)"
            @change="loadData"
            hide-details
            multiple
            dense
            solo
            flat
            required
            :style="{
              'box-shadow': 'rgba(0, 0, 0, 0.05) 0px 0px 0px 1px',
            }"
        /></v-col>

        <!-- date -->
        <v-scroll-y-transition>
          <v-col cols="12" v-if="enableDate">
            <v-row dense>
              <v-col cols="12" md="6">
                <v-dialog ref="pickerFromDialog" v-model="from.modal" :return-value.sync="from.value" width="290px">
                  <template v-slot:activator="{ on, attrs }">
                    <atoms-text-field label="Dari Tanggal">
                      <v-text-field
                        :loading="mainLoading"
                        class="rounded-lg"
                        placeholder="Dari Tanggal"
                        append-icon="mdi-calendar"
                        background-color="accent"
                        v-model="formatFromDate"
                        v-bind="attrs"
                        v-on="on"
                        hide-details
                        readonly
                        dense
                        solo
                        flat
                        :style="{
                          'box-shadow': 'rgba(0, 0, 0, 0.05) 0px 0px 0px 1px',
                        }"
                      />
                    </atoms-text-field>
                  </template>
                  <v-date-picker
                    :max="$moment().endOf('month').format('YYYY-MM-DD')"
                    v-model="from.value"
                    scrollable
                    class="rounded-lg"
                    @click:date="$refs.pickerFromDialog.save(from.value)"
                  />
                </v-dialog>
              </v-col>
              <v-col cols="12" md="6">
                <v-dialog ref="pickerToDialog" v-model="to.modal" :return-value.sync="to.value" width="290px">
                  <template v-slot:activator="{ on, attrs }">
                    <atoms-text-field label="Sampai Tanggal">
                      <v-text-field
                        class="rounded-lg"
                        placeholder="Sampai Tanggal"
                        append-icon="mdi-calendar"
                        background-color="accent"
                        v-model="formatToDate"
                        :loading="mainLoading"
                        v-bind="attrs"
                        v-on="on"
                        hide-details
                        dense
                        readonly
                        solo
                        flat
                        :style="{
                          'box-shadow': 'rgba(0, 0, 0, 0.05) 0px 0px 0px 1px',
                        }"
                      />
                    </atoms-text-field>
                  </template>
                  <v-date-picker
                    :max="$moment().endOf('month').format('YYYY-MM-DD')"
                    v-model="to.value"
                    scrollable
                    class="rounded-lg"
                    @click:date="$refs.pickerToDialog.save(to.value)"
                  />
                </v-dialog>
              </v-col>
            </v-row>
          </v-col>
        </v-scroll-y-transition>
      </v-row>
    </v-form>
    <!--x
     <div>
     
      <div v-if="mainLoading" class="mt-4">
        <v-row justify="center">
          <v-col
            v-for="i in 3"
            :key="i"
            cols="12"
            md="4"
            :class="!$vuetify.breakpoint.smAndDown && 'px-1'"
          >
            <v-card
              class="rounded-lg ma-md-2"
              :style="{
                'box-shadow': 'rgba(0, 0, 0, 0.05) 0px 0px 0px 1px',
              }"
              height="100%"
            >
              <div class="d-flex justify-center pt-8">
                <v-avatar size="100" color="background"></v-avatar>
              </div>
              <v-card-text style="width: 100%">
                <v-skeleton-loader width="100%" type="article" />
              </v-card-text>
            </v-card>
          </v-col>
        </v-row>
      </div>
      <v-row
        v-else-if="
          $store.state.client.data && $store.state.client.data.length > 0
        "
        justify="center"
        class="mt-4"
      >
        <v-col
          v-for="(x, i) in $store.state.client.data || []"
          :key="i"
          cols="12"
          md="4"
          :class="!$vuetify.breakpoint.smAndDown && 'px-1'"
        >
          <v-card
            class="accent rounded-lg ma-md-2 d-flex flex-column justify-start"
            :style="{
              'box-shadow': 'rgba(0, 0, 0, 0.05) 0px 0px 0px 1px',
            }"
            height="100%"
          >
            <v-card-actions class="align-center justify-end pb-0">
              <v-menu offset-x class="rounded-xl">
                <template v-slot:activator="{ on, attrs }">
                  <v-btn depressed icon v-bind="attrs" v-on="on"
                    ><v-icon>mdi-dots-vertical</v-icon></v-btn
                  >
                </template>
                <v-list nav dense elevation="0" class="text-center rounded-lg">
                  <v-list-item
                    @click="showEdit = x"
                    class="rounded-lg px-4 text-center"
                  >
                    <v-icon>mdi-pencil</v-icon>
                    <v-list-item-title class="px-2">Edit</v-list-item-title>
                  </v-list-item>
                  <v-list-item
                    :disabled="x.approved"
                    @click="
                      () => {
                        $store.dispatch('client/update', {
                          _id: x._id,
                          approved: true,
                        })
                        $showDialog({
                          title: 'Berhasil',
                          body: `${x.name} telah di approve`,
                        })
                        loadData()
                      }
                    "
                    class="rounded-lg px-4 text-center"
                  >
                    <v-icon>mdi-check</v-icon>
                    <v-list-item-title class="px-2">Approve</v-list-item-title>
                  </v-list-item>
                  <v-list-item
                    @click="
                      () => {
                        $store.dispatch('client/update', {
                          _id: x._id,
                          approved: false,
                        })
                        $showDialog({
                          title: 'Berhasil',
                          body: `${x.name} telah di reject`,
                        })
                        loadData()
                      }
                    "
                    link
                    class="rounded-lg px-4 text-center"
                  >
                    <v-icon>mdi-close</v-icon>
                    <v-list-item-title class="px-2">Reject</v-list-item-title>
                  </v-list-item>
                  <v-list-item
                    link
                    class="rounded-lg px-4 text-center"
                    @click="$router.push(`/areas/review?client=${x._id}`)"
                  >
                    <v-icon>mdi-map</v-icon>
                    <v-list-item-title class="px-2"
                      >Lihat Area</v-list-item-title
                    >
                  </v-list-item>
                  <v-list-item
                    link
                    :disabled="
                      !$store.state.user.roles.includes($roles.Super_Admin)
                    "
                    class="rounded-lg px-4 text-center"
                    @click="removeClient(x)"
                  >
                    <v-icon>mdi-delete</v-icon>
                    <v-list-item-title class="px-2">Hapus</v-list-item-title>
                  </v-list-item>
                </v-list>
              </v-menu>
            </v-card-actions>
            <v-card-title class="">
              <v-container class="flex text-center pt-0">
                <v-avatar size="100" color="background">
                  <atoms-image v-if="x.logo" :src="x.logo" :enableZoom="true" />
                  <v-icon v-else>mdi-domain</v-icon>
                </v-avatar>
                <atoms-title :h3="true" class="mt-4">{{ x.name }}</atoms-title>
                <v-chip
                  small
                  :class="[
                    'rounded-lg',
                    x.approved
                      ? 'green lighten-5 green--text'
                      : 'red lighten-5 red--text',
                  ]"
                  >{{
                    (x.approved == true && "Approved") ||
                    (x.approved == false && "Rejected") ||
                    "Baru"
                  }}</v-chip
                >
                <br />

                <atoms-text
                  v-if="balances && balances.find((it) => it._id === x._id)"
                  class="mt-2"
                  ><v-icon left>mdi-cash</v-icon
                  >{{
                    $idCurrency(
                      balances.find((it) => it._id === x._id).amount || 0
                    )
                  }}</atoms-text
                >
                <atoms-button v-else @click="checkSaldo(x)" small class="mt-2"
                  >Lihat Saldo</atoms-button
                >
              </v-container>
            </v-card-title>
            <v-card-text class="mb-auto">
              <div
                class="text-start background pa-2 pa-xl-4 my-auto rounded-lg"
              >
                <atoms-text
                  ><v-icon small left>mdi-map-marker</v-icon
                  >{{
                    (x.address && x.address.streetAddress1) ||
                    "Tidak dicantumkan"
                  }}</atoms-text
                >
                <v-divider class="my-2" />
                <atoms-text
                  ><v-icon small left>mdi-mail</v-icon>Email:
                  <span class="primary--text">{{
                    (x.contactPerson && x.contactPerson.email) ||
                    "Tidak dicantumkan"
                  }}</span></atoms-text
                >
                <atoms-text
                  ><v-icon small left>mdi-account</v-icon>Nama PIC:
                  {{
                    (x.contactPerson && x.contactPerson.name) ||
                    "Tidak dicantumkan"
                  }}</atoms-text
                >
                <atoms-text
                  ><v-icon small left>mdi-phone</v-icon>No.Telp PIC:
                  {{
                    (x.contactPerson && x.contactPerson.phone) ||
                    "Tidak dicantumkan"
                  }}</atoms-text
                >
                <atoms-text
                  ><v-icon small left>mdi-domain</v-icon>Industri:
                  {{ x.companyType || "Tidak dicantumkan" }}</atoms-text
                >
                <atoms-text
                  ><v-icon small left>mdi-web</v-icon>Website:
                  {{ x.website || "Tidak dicantumkan" }}</atoms-text
                >
                <v-divider class="my-2" />

                <atoms-text
                  ><v-icon small left>mdi-history</v-icon>Tanggal Daftar:
                  {{
                    (x._createdDate &&
                      $moment(x._createdDate).format(
                        "DD MMMM YYYY, HH:mm:ss"
                      )) ||
                    "-"
                  }}</atoms-text
                >
                <atoms-text
                  ><v-icon small left>mdi-information</v-icon>Tentang:
                  {{
                    (x.description && x.description.substr(0, 100) + "...") ||
                    "Tidak dicantumkan"
                  }}</atoms-text
                >
                <atoms-text
                  ><v-icon small left>mdi-account-edit-outline</v-icon
                  >Didaftarkan Oleh
                  {{
                    x.createdBy && x.isHkReg
                      ? x.createdBy.nickname + " (Pihak Hayokerja)"
                      : x.isHkReg
                      ? "Pihak Hayokerja"
                      : "Klien Tersebut"
                  }}</atoms-text
                >
              </div>
            </v-card-text>
            <v-card-text
              class="px-6 pb-8 d-flex align-end"
              style="height: 100%"
            >
              <v-row class="px-2">
                <v-col class="d-flex justify-start align-center">
                  <v-avatar class="mr-2 green lighten-5">
                    <v-icon color="green">mdi-cash</v-icon>
                  </v-avatar>
                  <div class="d-flex flex-column justify-center">
                    <atoms-title :h3="true" class="text-left">{{
                      8000
                    }}</atoms-title>
                    <atoms-text
                      :span="true"
                      :auto="true"
                      class="text-left green--text"
                      >Pendapatan</atoms-text
                    >
                  </div>
                </v-col>
                <v-col class="d-flex justify-start align-center">
                  <v-avatar class="mr-2 primary lighten-5">
                    <v-icon color="primary">mdi-account-multiple</v-icon>
                  </v-avatar>
                  <div class="d-flex flex-column justify-center">
                    <atoms-title :h3="true" class="text-left">{{
                      8000
                    }}</atoms-title>
                    <atoms-text
                      :span="true"
                      :auto="true"
                      class="text-left primary--text"
                      >Pekerja</atoms-text
                    >
                  </div>
                </v-col>
              </v-row>
            </v-card-text> 
          </v-card>
        </v-col>
        <v-col cols="12">
          <v-container class="max-width">
            <v-pagination
              class="my-4"
              v-model="page"
              :length="$store.state.client.pagesLength"
            ></v-pagination>
          </v-container>
        </v-col>
      </v-row>
      <molecules-wrapper
        v-else
        class="d-flex justify-center align-center my-8 py-8 outlined"
      >
        <div class="text-center">
          <atoms-image :width="250" src="/assets/missing.svg" />
          <atoms-title :h3="true" class="mt-6"
            >Client yang anda cari tidak ditemukan!</atoms-title
          >
        </div>
      </molecules-wrapper>
    </div>
 -->

    <div class="mt-4">
      <div v-if="mainLoading">
        <v-skeleton-loader
          v-for="i in 5"
          :key="i"
          type="list-item-avatar"
          class="accent rounded-lg mb-2 pa-2"
          :style="{
            'box-shadow': 'rgba(0, 0, 0, 0.05) 0px 0px 0px 1px',
          }"
        />
      </div>
      <div v-else>
        <div v-if="$store.state.client.data && $store.state.client.data.length > 0">
          <atoms-button
            style="width: 100%"
            class="teal lighten-5 teal--text text--darken-2 my-3"
            @click.prevent="exportXlsx"
            ><v-icon left>mdi-google-spreadsheet</v-icon>Export XLSX</atoms-button
          >
          <div
            v-for="(x, i) in $store.state.client.data || []"
            :key="i"
            :class="['accent', 'rounded-lg pa-4 mb-2']"
            :style="{
              'box-shadow': 'rgba(0, 0, 0, 0.05) 0px 0px 0px 1px',
            }"
          >
            <v-row align="center">
              <v-col class="shrink order-last order-md-first">
                <v-btn
                  depressed
                  icon
                  @click="
                    () => {
                      {
                        const indexOfRow = expandedIds.indexOf(i)
                        if (indexOfRow > -1) {
                          expandedIds.splice(indexOfRow, 1)
                          return
                        }
                        expandedIds.push(i)
                      }
                    }
                  "
                  ><v-icon>mdi-chevron-{{ expandedIds.includes(i) ? 'up' : 'down' }}</v-icon></v-btn
                >
              </v-col>
              <v-col class="shrink">
                <v-avatar size="50" color="grey lighten-4 rounded-pill">
                  <atoms-image v-if="x.logo" :src="x.logo" :enableZoom="true" />
                  <v-icon light v-else>mdi-domain</v-icon></v-avatar
                >
              </v-col>
              <v-col cols="8" md="4">
                <atoms-text :auto="true" class="font-weight-bold text-uppercase">
                  {{ (x.code && `(${x.code}) - `) || '' }}
                  {{ (x.name && x.name) || 'Client' }}
                </atoms-text>
                <atoms-text class="primary--text">{{ (x.address && x.address.streetAddress1) || '-' }}</atoms-text>
              </v-col>
              <v-col cols="12" md="auto" align="start">
                <atoms-text>Client sejak {{ $moment(x._createdDate).format('dddd, DD MMMM YYYY') }}</atoms-text>
                <div>
                  <atoms-text v-if="balances && balances.find((it) => it._id === x._id)" class="mt-2"
                    ><v-icon left>mdi-cash</v-icon
                    >{{ $idCurrency(balances.find((it) => it._id === x._id).amount || 0) }}</atoms-text
                  >
                  <atoms-button v-else @click="checkSaldo(x)" small class="mt-2">Lihat Saldo</atoms-button>
                </div>
              </v-col>
              <v-col :align="$vuetify.breakpoint.smAndDown ? 'start' : 'center'">
                <v-chip
                  small
                  :class="['rounded-lg', x.approved ? 'green lighten-5 green--text' : 'red lighten-5 red--text']"
                  >{{ (x.approved == true && 'Approved') || (x.approved == false && 'Rejected') || 'Baru' }}</v-chip
                >
              </v-col>
              <v-col class="shrink">
                <v-menu offset-x class="rounded-xl">
                  <template v-slot:activator="{ on, attrs }">
                    <v-btn depressed icon v-bind="attrs" v-on="on"><v-icon>mdi-dots-vertical</v-icon></v-btn>
                  </template>
                  <v-list nav dense elevation="0" class="text-center rounded-lg">
                    <v-list-item @click="showEdit = x" class="rounded-lg px-4 text-center">
                      <v-icon>mdi-pencil</v-icon>
                      <v-list-item-title class="px-2">Edit</v-list-item-title>
                    </v-list-item>
                    <v-list-item
                      :disabled="x.approved"
                      @click="
                        () => {
                          $store.dispatch('client/update', {
                            _id: x._id,
                            approved: true,
                          })
                          $showDialog({
                            title: 'Berhasil',
                            body: `${x.name} telah di approve`,
                          })
                          loadData()
                        }
                      "
                      class="rounded-lg px-4 text-center"
                    >
                      <v-icon>mdi-check</v-icon>
                      <v-list-item-title class="px-2">Approve</v-list-item-title>
                    </v-list-item>
                    <v-list-item
                      @click="
                        () => {
                          $store.dispatch('client/update', {
                            _id: x._id,
                            approved: false,
                          })
                          $showDialog({
                            title: 'Berhasil',
                            body: `${x.name} telah di reject`,
                          })
                          loadData()
                        }
                      "
                      link
                      class="rounded-lg px-4 text-center"
                    >
                      <v-icon>mdi-close</v-icon>
                      <v-list-item-title class="px-2">Reject</v-list-item-title>
                    </v-list-item>
                    <v-list-item
                      link
                      class="rounded-lg px-4 text-center"
                      @click="$router.push(`/areas/review?client=${x._id}`)"
                    >
                      <v-icon>mdi-map</v-icon>
                      <v-list-item-title class="px-2">Lihat Area</v-list-item-title>
                    </v-list-item>
                    <v-list-item
                      link
                      v-if="$store.state.user.roles.includes($roles.Super_Admin)"
                      class="rounded-lg px-4 text-center"
                      @click="removeClient(x)"
                    >
                      <v-icon>mdi-delete</v-icon>
                      <v-list-item-title class="px-2">Hapus</v-list-item-title>
                    </v-list-item>
                  </v-list>
                </v-menu>
              </v-col>
            </v-row>
            <molecules-wrapper v-if="expandedIds.includes(i)" class="px-0 pb-0">
              <div>
                <atoms-text class="font-weight-bold">Kontak PIC</atoms-text>
                <div>
                  <atoms-text>Email : {{ (x.contactPerson && x.contactPerson.email) || '-' }}</atoms-text>
                  <atoms-text>PIC : {{ (x.contactPerson && x.contactPerson.name) || '-' }}</atoms-text>
                  <atoms-text>Nomor template : {{ (x.contactPerson && x.contactPerson.phone) || '-' }}</atoms-text>
                </div>
              </div>
              <div>
                <atoms-text class="font-weight-bold">Detail</atoms-text>
                <div>
                  <atoms-text>NPWP : {{ x.npwp || '-' }}</atoms-text>
                  <atoms-text>Tipe Perusahaan : {{ x.companyType || '-' }}</atoms-text>
                </div>
              </div>
              <atoms-text v-if="x.areas && x.areas.length > 0" class="font-weight-bold">Area </atoms-text>
              <v-tooltip bottom v-for="(x, i) in x.areas || []" :key="i">
                <template v-slot:activator="{ on, attrs }">
                  <v-chip
                    @click="$router.push(`/areas/location?direct=${x._id}`, '_blank')"
                    small
                    v-bind="attrs"
                    v-on="on"
                    class="rounded-lg mr-1 my-1"
                    style="cursor: pointer"
                  >
                    <span class="">
                      {{ x.title }}
                    </span>
                    <v-badge
                      v-if="x.inactive"
                      color="red darken-1"
                      style="opacity: 0.8"
                      class="pl-3"
                      dot
                      title="Area inactive"
                    ></v-badge>
                  </v-chip>
                </template>
                <span>Lihat Area {{ x.title }}</span>
              </v-tooltip>
            </molecules-wrapper>
          </div>
          <v-row justify="center">
            <v-col cols="8">
              <molecules-wrapper class="max-width">
                <v-pagination
                  class="my-4"
                  v-model="page"
                  @change="loadData"
                  :length="$store.state.client.pagesLength"
                ></v-pagination>
              </molecules-wrapper>
            </v-col>
          </v-row>
        </div>
        <molecules-wrapper v-else class="d-flex flex-column justify-center align-center my-8 py-8 outlined">
          <div><atoms-image :width="250" src="/assets/missing.svg" /></div>
          <atoms-title :h3="true" class="mt-6">User yang anda cari tidak ditemukan!</atoms-title>
        </molecules-wrapper>
      </div>
    </div>

    <v-slide-x-reverse-transition>
      <organism-clients-register
        v-if="showAdd"
        @closed="
          () => {
            showAdd = false
          }
        "
      />
    </v-slide-x-reverse-transition>
    <v-slide-x-reverse-transition>
      <organism-clients-edit
        v-if="showEdit"
        :data="showEdit"
        @closed="
          () => {
            showEdit = null
          }
        "
      />
    </v-slide-x-reverse-transition>
  </div>
  <div v-else>
    <atoms-text
      >Akses Halaman di
      <a href="https://hayosystems-v3.web.app/clients">https://hayosystems-v3.web.app/clients</a></atoms-text
    >
  </div>
</template>
<script>
export default {
  data() {
    return {
      balances: null,
      sort: '',
      constant: ['Desc', 'Asc'],
      term: '',
      showAdd: false,
      showEdit: null,
      expandedIds: [],
      filter: [],
      client: {
        enabled: false,
        value: '',
        item: null,
        items: null,
        term: '',
      },
      searchFilter: null,
      searchFilters: [
        {
          title: 'Kode Client',
          value: (term) => ({
            code: { $regex: term, $options: 'i' },
          }),
        },
        {
          title: 'Nama Client',
          value: (term) => ({
            name: {
              $regex: term,
              $options: 'i',
            },
          }),
        },
        {
          title: 'Nama PIC',
          value: (term) => ({
            'contactPerson.name': {
              $regex: term,
              $options: 'i',
            },
          }),
        },
        {
          title: 'Email PIC',
          value: (term) => ({
            'contactPerson.email': {
              $regex: term,
              $options: 'i',
            },
          }),
        },
        {
          title: 'Email',
          value: (term) => ({
            email: {
              $regex: term,
              $options: 'i',
            },
          }),
        },
        {
          title: 'Alamat',
          value: (term) => ({
            'address.streetAddress1': {
              $regex: term,
              $options: 'i',
            },
          }),
        },
      ],
      filters: [
        {
          title: 'Baru',
          value: {
            approved: {
              $exists: false,
            },
          },
        },
        {
          title: 'Didaftarkan Hayokerja',
          value: {
            isHkReg: true,
          },
        },
        {
          title: 'Approved',
          value: {
            approved: true,
          },
        },
        {
          title: 'Rejected',
          value: {
            approved: false,
          },
        },
      ],
      reportLoading: true,
      mainLoading: true,
      page: 1,
      limit: 10,
      enableDate: false,
      from: {
        modal: false,
        value: this.$moment().startOf('month').format('YYYY-MM-DD'),
      },
      to: {
        modal: false,
        value: this.$moment().format('YYYY-MM-DD'),
      },
    }
  },
  computed: {
    formatFromDate: {
      set(value) {
        this.from.value = this.$moment(value).format('YYYY-MM-DD')
      },
      get() {
        return this.$moment(this.from.value).format('DD MMMM YYYY')
      },
    },
    formatToDate: {
      set(value) {
        this.to.value = this.$moment(value).format('YYYY-MM-DD')
      },
      get() {
        return this.$moment(this.to.value).format('DD MMMM YYYY')
      },
    },
  },
  watch: {
    'client.value'() {
      if (this.client.value) {
        this.client.item = this.$store.state.client?.data?.find((x) => x.name === this.client.value)
        this.loadData()
      }
    },
    enableDate: function (newValue) {
      this.getClients()
    },
    'from.value': function (newValue) {
      if (newValue) {
        this.getSummary()
        this.getClients()
      }
    },
    'to.value': function (newValue) {
      if (newValue) {
        this.getSummary()
        this.getClients()
      }
    },
    page: {
      handler() {
        this.getSummary()
        this.getClients()
      },
      deep: true,
    },
  },
  async mounted() {
    // window.open('https://hayosystems-v3.web.app/clients', '_blank')
    this.sort = this.constant[0]
    // this.filter?.push(this.filters[0].title)
    if (this.$route?.query?._id) {
      const result = (
        await this.$store.dispatch('client/getAll', {
          query: {
            _id: this.$route?.query?._id,
          },
          page: 1,
          limit: 1,
        })
      )?.[0]
      this.showEdit = result
    }
    this.loadData()
  },
  methods: {
    async checkSaldo(payload) {
      this.$store.commit('SET', { loading: true })

      const { result, ...rest } = await this.$api.get(`Clients/getBalance`, {
        params: {
          // jsonQuery: JSON.stringify({
          _id: payload._id,
          // }),
        },
      })
      if (rest?.success) {
        this.balances = [
          ...(this.balances || []),
          {
            _id: payload._id,
            amount: result,
          },
        ]
      }
      this.$store.commit('SET', { loading: false })
    },
    loadData() {
      this.page = 1
      this.getSummary()
      this.getClients()
    },
    async getSummary() {
      this.reportLoading = true
      try {
        await this.$store.dispatch('client/summary')
      } catch (error) {
        Promise.reject(error)
      } finally {
        this.reportLoading = false
      }
    },
    getFilter() {
      const query = {
        ...(!this.client.item?._id && this.filter && this.filter.length > 0
          ? Object.assign({}, ...this.filter?.map((x) => this.filters?.find((y) => y.title === x)?.value))
          : []),
        pipeline: [
          {
            $sort: {
              _createdDate: this.sort === this.constant[0] ? -1 : 1,
            },
          },
          {
            $lookup: {
              from: 'Users',
              localField: 'createdBy',
              foreignField: '_id',
              as: 'createdBy',
            },
          },
          {
            $unwind: {
              path: '$createdBy',
              preserveNullAndEmptyArrays: true,
            },
          },
          {
            $lookup: {
              from: 'Areas',
              localField: '_id',
              foreignField: 'clientId',
              as: 'areas',
            },
          },
          {
            $lookup: {
              from: 'Locations',
              localField: 'areas._id',
              foreignField: 'areaId',
              as: 'locations',
            },
          },
          {
            $project: {
              'createdBy.password': 0,
              'updatedBy.password': 0,
            },
          },
        ],
      }
      if (this.client?.item?._id) {
        query._id = this.client.item._id
      }
      if (this.enableDate) {
        query._createdDate = {
          $gte: new Date(new Date(this.from.value).setHours(0, 0, 0, 0)),
          $lt: new Date(new Date(this.to.value).setHours(45, 0, 0, 0)),
        }
      }
      if (this.client?.term) {
        query.$or = [
          ...(this.searchFilter?.length > 0
            ? this.searchFilter?.map((x) => this.searchFilters?.find((y) => y.title === x)?.value?.(this.client.term))
            : this.searchFilters.map((x) => x?.value?.(this.client.term))),
        ]
      }
      return query
    },
    async getClients() {
      console.log(this.getFilter())
      this.mainLoading = true
      try {
        await this.$store.dispatch('client/getAll', {
          custom: this.getFilter(),
          page: this.page,
          limit: this.limit,
        })
      } catch (error) {
        Promise.reject(error)
      } finally {
        this.mainLoading = false
      }
    },
    async removeClient(client) {
      const isConfirmed = confirm(`Yakin ingin hapus klien ${client.name}?`)
      if (!isConfirmed) return
      this.mainLoading = true
      try {
        await this.$api.delete('Clients/remove', {
          params: {
            _id: client?._id,
          },
        })
        await this.getClients()
      } catch (errMessage) {
        alert(errMessage)
      }
      this.mainLoading = false
    },
    async exportXlsx() {
      this.$store.commit('SET', { loading: true })
      const jsonQuery = this.getFilter()
      // handle xlsx
      const realm = await this.$realm()
      const clients = await realm.functions.getClients(JSON.stringify(jsonQuery))
      const columns = [
        {
          label: '_id',
          value: '_id',
        },
        {
          label: 'Kode Klien',
          value: 'Kode Klien',
        },
        {
          label: 'Nama Klien',
          value: 'Nama Klien',
        },
        {
          label: 'Nama PIC',
          value: 'Nama PIC',
        },
        {
          label: 'No Telp PIC',
          value: 'No Telp PIC',
        },
        {
          label: 'Email PIC',
          value: 'Email PIC',
        },
      ]
      const content = clients.map((item) => ({
        _id: item?._id,
        'Kode Klien': item?.code,
        'Nama Klien': item?.name,
        'Nama PIC': item?.contactPerson?.name,
        'No Telp PIC': item?.contactPerson?.phone,
        'Email PIC': item?.contactPerson?.email,
      }))
      let data = [
        {
          sheet: 'Clients',
          columns,
          content,
        },
      ]
      let settings = {
        fileName: 'Clients',
      }
      this.$xlsx(data, settings)
      this.$store.commit('SET', { loading: false })
    },
  },
}
</script>
